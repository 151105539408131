import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../core/authentication/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  profileForm = new FormGroup({
    email: new FormControl('', Validators.email),
    password: new FormControl('', Validators.required),
  });

  constructor(private authService: AuthService) {
  }

  isError = {
    status: false,
    msg: ''
  }

  submit() {
    this.isError.status = false
    this.authService.login(this.profileForm.value.email, this.profileForm.value.password)
      .subscribe(response => {
        },
        error => {
          this.isError = {
            status: true,
            msg: error.error.error
          }
        })
  }
}
