import {Component, OnInit} from '@angular/core'
import {AuthService} from '../core/authentication/auth.service';
import {Router} from '@angular/router';


export interface RouteInfo {
  path: string
  title: string
  icon: string
}

export const ROUTES: RouteInfo[] = [
  {path: '/dashboard', title: 'Dashboard', icon: 'nc-bank'},
  {path: '/events', title: 'Események', icon: 'nc-tile-56'}
]

@Component({
  moduleId: module.id,
  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})

export class SidebarComponent implements OnInit {
  public menuItems: any[]

  constructor(private authService: AuthService, private router: Router) {
  }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem)
  }

  logout() {
    this.authService.logout()
    this.router.navigate(['/login'])
  }
}
