import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {NgModule} from '@angular/core'
import {RouterModule} from '@angular/router'
import {ToastrModule} from 'ngx-toastr'

import {SidebarModule} from './sidebar/sidebar.module'
import {NavbarModule} from './shared/navbar/navbar.module'

import {AppComponent} from './app.component'
import {AppRoutes} from './app.routing'

import {AdminLayoutComponent} from './layouts/admin-layout/admin-layout.component'
import {LoginComponent} from './pages/login/login.component'


import {ReactiveFormsModule} from '@angular/forms'
import {HttpClientModule} from '@angular/common/http'
import {CommonModule} from '@angular/common';
import {NgxDropzoneModule} from 'ngx-dropzone';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {EventComponent} from './pages/event/event.component';


@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    EventComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes, {
      useHash: true
    }),
    SidebarModule,
    NavbarModule,
    ToastrModule.forRoot(),
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    NgxDropzoneModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
