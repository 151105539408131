import {Injectable} from '@angular/core';
import {ApiService} from '../http/api.service';
import {BehaviorSubject} from 'rxjs';

export interface ITable<T> {
  count: number
  total: number
  currentPage: number
  lastPage: number
  prev: string
  next: string
  data: T[]
}

@Injectable({
  providedIn: 'root'
})
export class TableService {

  constructor(private api: ApiService) {
  }

  createNewTable() {
    const subject = new BehaviorSubject<ITable<any>>(null)

    const sub = this.api.get<{ data: ITable<any> }>('/api/events').subscribe(data => {
      subject.next(data.data)
      sub.unsubscribe()
    })

    return {
      table: subject.asObservable(),
      updateCurrentPage: () => this.update(subject),
      goToPage: (page: number) => this.goToPage(page, subject)
    }
  }


  private update(subject: BehaviorSubject<ITable<any>>) {
    const sub = this.api.get<{ data: ITable<any> }>('/api/events', {
      page: subject.getValue().currentPage.toString()
    }).subscribe(data => {
      subject.next(data.data)
      sub.unsubscribe()
    })
  }

  private goToPage(page: number, subject: BehaviorSubject<ITable<any>>) {
    const sub = this.api.get<{ data: ITable<any> }>('/api/events', {
      page: page.toString()
    }).subscribe(data => {
      subject.next(data.data)
      sub.unsubscribe()
    })
  }
}
