import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {finalize, map, shareReplay, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import jwt_decode from 'jwt-decode';
import { environment } from 'environments/environment'

export interface IUser {
  token: string
  profile: any
}

export enum UserTypes {
  admin = 'organizationUser',
  user = 'eventUser'
}

export const USER_DATA = 'userData'

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private subject = new BehaviorSubject<IUser>(null)

  user: Observable<IUser> = this.subject.asObservable()
  isLoggedIn: Observable<boolean>
  isLoggedOut: Observable<boolean>

  constructor(private http: HttpClient, private router: Router) {
    this.isLoggedIn = this.user.pipe(map(user => !!user))
    this.isLoggedOut = this.isLoggedIn.pipe(map(loggedIn => !loggedIn))
    if (localStorage[USER_DATA]) {
      this.subject.next(JSON.parse(localStorage[USER_DATA]))
    }
  }

  private _apiUrl = environment.apiUrl

  login(email: string, password: string): Observable<any> {
    return this.http.post<{ data: IUser }>(this._apiUrl + '/api/auth/login', {email, password})
      .pipe(
        map(data => {
          const decodedToken: any = jwt_decode(data.data.token)

          if (decodedToken.user_type !== UserTypes.admin) {
            throw ({error: {error: 'You are not organization member'}})
          }
          localStorage[USER_DATA] = JSON.stringify(data.data)
          this.subject.next(data.data)
          this.router.navigate(['dashboard'])

          return data
        }),
        shareReplay()
      )
  }

  logout() {
    this.subject.next(null)
    delete localStorage[USER_DATA]
  }


}
