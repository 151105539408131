import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Router} from '@angular/router';
import {IUser, USER_DATA} from '../authentication/auth.service';
import {catchError} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {environment} from "../../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {
  }

  apiUrl = environment.apiUrl
  private URL_BUILDER = (path: string) => this.apiUrl + path

  private static HTTP_OPTIONS_BUILDER(params: { [param: string]: string }): { headers?: { [param: string]: string }, params?: { [param: string]: string | string[] } } {
    let options: { headers?: { [param: string]: string }, params?: { [param: string]: string | string[] } } = {}
    const token = (JSON.parse(localStorage[USER_DATA]) as IUser | undefined)?.token
    if (token) {
      options.headers = {
        'Authorization': 'Bearer ' + token
      }
    }
    options.params = params

    return options
  }

  private onError(err: HttpErrorResponse): Observable<any> {
    if (err.status === 401 || err.error.data === 'Unauthenticated') {
      this.router.navigate(['/login'])
    }
    throw(err)
  }

  public get<T>(path: string, params: { [param: string]: string } = {}): Observable<T> {
    const options = ApiService.HTTP_OPTIONS_BUILDER(params)
    return this.http.get<T>(this.URL_BUILDER(path), options).pipe(
      catchError(err => this.onError(err))
    )
  }

  public post<T>(path: string, body: any = {}, params: { [param: string]: string } = {}): Observable<T> {
    const options = ApiService.HTTP_OPTIONS_BUILDER(params)
    return this.http.post<T>(this.URL_BUILDER(path), body, options).pipe(
      catchError(err => this.onError(err))
    )
  }

  public delete<T>(path: string, body: any = {}, params: { [param: string]: string } = {}): Observable<T> {
    const options = ApiService.HTTP_OPTIONS_BUILDER(params)
    return this.http.delete<T>(this.URL_BUILDER(path), options).pipe(
      catchError(err => this.onError(err))
    )
  }
}
