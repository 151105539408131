import {Component, OnDestroy, OnInit} from '@angular/core';
import {EventFormService} from '../../core/services/event-form.service';
import {TableService} from '../../core/services/table.service';
import {AuthService} from '../../core/authentication/auth.service';
import {FormControl, FormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.css']
})
export class EventComponent implements OnInit, OnDestroy {
  constructor(
    private eventFormService: EventFormService,
    private tableService: TableService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService) {
  }

  id: number

  _formIsReady = true
  get formIsReady() {
    return this._formIsReady
  }

  set formIsReady(b: boolean) {
    if (b) {
      Object.keys(this.eventForm.controls).forEach(control => this.eventForm.controls[control].enable())
    } else {
      Object.keys(this.eventForm.controls).forEach(control => this.eventForm.controls[control].disable())
    }
    this._formIsReady = b
  }

  eventForm = new FormGroup({
    name: new FormControl(),
    from_date: new FormControl(),
    to_date: new FormControl(),
    deadline: new FormControl(),
    address: new FormControl(),
    description: new FormControl(),
  })

  bannerFile: File
  thumbnailFile: File

  subs: Subscription[] = []

  ngOnInit() {
    this.subs.push(this.route.params.subscribe(params => {
      if (params['id']) {
        this.id = +params['id']
        this.loadEventDataById()
      }
    }))
  }

  ngOnDestroy = () => this.subs.forEach(sub => sub.unsubscribe())

  loadEventDataById() {
    this.formIsReady = false
    this.subs.push(this.eventFormService.eventDetails(this.id).subscribe(response => {
      const from_date = new Date(response.data.from_date)
      const to_date = new Date(response.data.to_date)
      const deadline = new Date(response.data.deadline)
      this.eventForm.patchValue({
        name: response.data.event_name,
        from_date: {
          year: from_date.getFullYear(),
          month: from_date.getMonth() + 1,
          day: from_date.getDate()
        },
        to_date: {
          year: to_date.getFullYear(),
          month: to_date.getMonth() + 1,
          day: to_date.getDate()
        },
        deadline: {
          year: deadline.getFullYear(),
          month: deadline.getMonth() + 1,
          day: deadline.getDate()
        },
        address: response.data.event_address,
        description: response.data.description,
      })
      this.formIsReady = true
    }))
  }

  private onSuccess() {
    this.router.navigate(['/events'])
  }

  private onErr(err) {
    if (err?.status === 401) {
      this.authService.logout()
    }
  }

  onFormSubmit() {
    if (this.id) {
      this.eventUpdate()
    } else {
      this.eventCreate()
    }
  }

  eventCreate() {
    this.subs.push(this.eventFormService.eventCreate(this.prepareBody()).subscribe(
      response => this.onSuccess(),
      err => this.onErr(err)
    ))
  }

  eventUpdate() {
    this.subs.push(this.eventFormService.eventUpdate(this.id, this.prepareBody()).subscribe(
      response => this.onSuccess(),
      err => this.onErr(err)
    ))
  }

  eventDelete() {
    this.subs.push(this.eventFormService.eventDelete(this.id).subscribe(
      (response) => this.onSuccess(),
      err => this.onErr(err)
    ))
  }

  prepareBody() {
    const data = this.eventForm.getRawValue()
    return {
      ...data,
      from_date: this.dateToString(data.from_date),
      to_date: this.dateToString(data.to_date),
      deadline: this.dateToString(data.deadline),
      banner: this.bannerFile,
      thumbnail: this.thumbnailFile
    }
  }

  isFormValidForSubmit(): boolean {
    if (this.id) {
      return this.eventForm.dirty && this.eventForm.valid
    } else {
      return this.eventForm.valid && !!this.bannerFile && !!this.thumbnailFile
    }
  }

  dateToString = (date) => date ? `${date.year}-${date.month}-${date.day}` : null
}
