import {Injectable} from '@angular/core';
import {ApiService} from "../http/api.service";

export interface EventData {
  name: string
  from_date: string
  to_date: string
  deadline: string
  address: string
  description: string
  banner: File
  thumbnail: File
}

export interface EventDetails extends Omit<EventData, 'name' | 'address' | 'banner' | 'thumbnail'> {
  id: number
  event_name: string
  event_address: string
}

export interface EventDataUpdate extends Omit<EventData, 'banner'| 'thumbnail'> {
  banner?: File
  thumbnail?: File
}

@Injectable({
  providedIn: 'root'
})
export class EventFormService {

  constructor(private api: ApiService) {
  }

  private dataToFormData(data: { [key: string]: string | any }): FormData {
    const fd = new FormData()
    for (const key in data) {
      if (!data[key]) continue
      if (typeof (data[key]) === 'string')
        fd.append(key, data[key])
      else
        fd.append(key, data[key], data[key].name)
    }
    return fd
  }

  eventCreate(data: EventData) {
    const fd = this.dataToFormData(data)

    return this.api.post('/api/event/register', fd)
  }

  eventUpdate(id: number, data: EventDataUpdate) {
    const fd = this.dataToFormData(data)
    return this.api.post('/api/events/' + id, fd)
  }

  eventDetails(id: number) {
    return this.api.get<{ data: EventDetails }>('/api/event/' + id)
  }

  eventDelete(id: number) {
    return this.api.delete('/api/events/' + id)
  }
}
