import { Component } from '@angular/core';
import {Router} from '@angular/router';

const NO_NAV_ROUTES = [
  '/event'
]

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent {

  constructor(private router: Router) {
  }

  isNavActive = () => !NO_NAV_ROUTES.includes(this.router.url)
}
